@font-face {
  font-family: 'Vermin Vibes 1989';
  src: url('../fonts/vermin.eot');
  src: url('../fonts/vermin.eot') format('embedded-opentype'),
    url('../fonts/vermin.woff2') format('woff2'),
    url('../fonts/vermin.woff') format('woff'),
    url('../fonts/vermin.ttf') format('truetype'),
    url('../fonts/vermin.svg') format('svg');
}

body {
  margin: 0;
  color: #fff;
}

.font-pixel {
  font-family: 'Vermin Vibes 1989', cursive;
}

.navbar {
  border-bottom: 1px solid #d56730;
}

.social a {
  margin-right: 0.5rem;
  text-decoration: none;
  color: #fff;
}
.social img {
  height: 1.5rem;
}

header.container-fluid {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)),
    url(../img/background.jpg);
  background-position: center;
  background-size: cover;
  min-height: 100vh;
}
input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.input-mint-container,
.input-mint-container::after {
  width: 220px;
  height: 43px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}
.input-mint,
.input-mint::after {
  width: 60%;
  height: 43px;
  /* font-weight: 800; */
  font-size: 16px;
  font-family: 'Vermin Vibes 1989';
  background: #fff;
  border: 2px solid #d56730;
  color: #0a0a0a;
  letter-spacing: 3px;
  line-height: 32px;

  outline: transparent;
  position: relative;
  padding: 20px;
  margin-left: -4px;
  margin-right: -4px;
}
.btn-input {
  margin: 0;
  font-weight: 800;
  font-size: 18px;
  font-family: 'Vermin Vibes 1989';
  background: #d56730;
  border: 2px solid #d56730;
  text-align: center;
  color: #fff;
  /* letter-spacing: 3px; */
  line-height: 32px;
  outline: transparent;
  position: relative;
  padding: 4px;
}

.input-mint-input {
  width: 60%;
}
.btn-max {
  width: 40%;
  margin: 0;
  font-weight: 800;
  font-size: 18px;
  font-family: 'Vermin Vibes 1989';
  background: linear-gradient(225deg, transparent 5%, #d56730 5%);
  border: 0;
  text-align: center;
  color: #fff;
  /* letter-spacing: 3px; */
  line-height: 32px;
  outline: transparent;
  position: relative;
  padding: 8px;
}

/* .btn-cta,
.btn-cta::after {
  width: 220px;
  height: 43px;
  font-size: 16px;
  font-family: 'Vermin Vibes 1989';
  background: linear-gradient(45deg, transparent 5%, #d56730 5%);
  border: 0;
  color: #fff;
  letter-spacing: 3px;
  line-height: 16px;
  /* box-shadow: 6px 0px 0px #ad440f; */
/* outline: transparent;
  position: relative;
} */

/* .btn-cta:hover {

} */

/* CSS */
.btn-cta {
  appearance: none;
  /* font-family: 'Vermin Vibes 1989'; */
  background: linear-gradient(45deg, transparent 5%, #d56730 5%);
  border-width: 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 3px;
  line-height: 16px;
  margin: 0;
  opacity: 1;
  outline: 0;
  /* padding: 1.5em 2.2em; */
  position: relative;
  text-align: center;
  text-decoration: none;
  text-rendering: geometricprecision;
  text-transform: uppercase;
  transition: opacity 300ms cubic-bezier(0.694, 0, 0.335, 1),
    background-color 100ms cubic-bezier(0.694, 0, 0.335, 1),
    color 100ms cubic-bezier(0.694, 0, 0.335, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
  width: 220px;
  height: 43px;
}

.btn-cta:before {
  animation: opacityFallbackOut 0.5s step-end forwards;
  backface-visibility: hidden;
  background-color: #9c4417;
  clip-path: polygon(100% 0, 100% 100%, 97% 100%, 97% 65%, 97% 0);
  content: '';
  height: 43px;
  left: 0;
  position: absolute;
  top: 0;
  transform: translateZ(0);
  transition: clip-path 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-clip-path 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  width: 220px;
}

/* .btn-cta:hover:before {
  animation: opacityFallbackIn 0s step-start forwards;
  clip-path: polygon(100% 0, 100% 100%, 6% 100%, 0 69%, 0 0);
} */

.btn-cta:after {
  background-color: #9c4417;
}

.btn-cta span {
  /* z-index: 1; */
  position: relative;
}

.btn-max {
  appearance: none;
  font-family: 'Vermin Vibes 1989';
  background: linear-gradient(225deg, transparent 5%, #d56730 5%);
  border-width: 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 3px;
  line-height: 16px;
  margin: 0;
  opacity: 1;
  outline: 0;
  /* padding: 1.5em 2.2em; */
  position: relative;
  text-align: center;
  text-decoration: none;
  text-rendering: geometricprecision;
  text-transform: uppercase;
  transition: opacity 300ms cubic-bezier(0.694, 0, 0.335, 1),
    background-color 100ms cubic-bezier(0.694, 0, 0.335, 1),
    color 100ms cubic-bezier(0.694, 0, 0.335, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
  width: 40%;
  height: 43px;
}

.btn-max:before {
  animation: opacityFallbackOut 0.5s step-end forwards;
  backface-visibility: hidden;
  background-color: #9c4417;
  clip-path: polygon(10% 30%, 10% 100%, 0% 100%, 0 0, 10% 0);
  content: '';
  height: 43px;
  left: 0;
  position: absolute;
  top: 0;
  transform: translateZ(0);
  transition: clip-path 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-clip-path 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  width: 100%;
}

.btn-max:hover:before {
  animation: opacityFallbackIn 0s step-start forwards;
  clip-path: polygon(100% 16%, 100% 100%, 0 100%, 0 0, 92% 0);
  /* z-index: 1; */
}

.btn-max:after {
  background-color: #9c4417;
}

.btn-max span {
  /* z-index: 9999; */
  position: relative;
}

.xmodal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.xmodal-content {
  background-color: #0a0a0a;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(180deg, #d56730, #d5673041);
  width: 30%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.xclose {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.xclose:hover,
.xclose:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.header-font {
  font-family: 'Vermin Vibes 1989';
}

.btn-secondary {
  background-color: transparent;
  border: 2px solid #d56730;
}

.btn-secondary:hover {
  background-color: #d56730;
  border: 2px solid #d56730;
}

.nav-link {
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  text-decoration: none;
  color: #d56730;
  padding: 12px 0px;
  margin-left: 16px;
  margin-right: 16px;
  display: inline-block;
  position: relative;
  opacity: 0.75;
}

.nav-link:hover {
  opacity: 1;
}

.nav-link::before {
  transition: 300ms;
  height: 5px;
  content: '';
  position: absolute;
  background-color: #d56730;
}

.nav-link-ltr::before {
  width: 0%;
  bottom: 0px;
}

.nav-link-ltr:hover::before {
  width: 100%;
}

.btn-discord,
.btn-discord::after {
  width: 240px;
  height: 43px;
  font-size: 16px;
  font-family: 'Vermin Vibes 1989';
  background: linear-gradient(45deg, transparent 5%, #d56730 5%);
  border: 0;
  color: #fff;
  letter-spacing: 3px;
  line-height: 32px;
  box-shadow: 6px 0px 0px #ad440f;
  outline: transparent;
  position: relative;
}

.alert-dark {
  background-color: #0a0a0a;
  color: #fff;
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(180deg, #d56730, #d5673041);
}

.alert-tabs {
  background-color: #0a0a0a;
  color: #fff;
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(180deg, #0a0a0a, #d56730);
}

#preview {
  background: #0a0a0a;
}

#preview .voxel-viewer {
  width: 100%;
}

#roadmap {
  background: #0a0a0a;
}
#roadmap .alert .col-md-3 .img-fluid {
  filter: drop-shadow(5px 5px 15px #fff);
}

@media (min-width: 767.98px) {
  #roadmap .alert.row {
    margin-bottom: 5rem;
  }
  #roadmap .alert .col-md-3 .trex {
    margin-top: -8rem;
  }
  #roadmap .alert .col-md-3 .shoes {
    margin-top: -3.5rem;
  }
  #roadmap .alert .col-md-3 .token {
    margin-top: -5rem;
  }
}

#cta {
  background: linear-gradient(
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 1)
    ),
    url(../img/discord-cta.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

footer {
  background-color: #000;
}

.nes-icon.discord::before {
  width: 1px;
  height: 1px;
  color: #7298da;
  box-shadow: 2px 1px, 3px 1px, 4px 1px, 5px 1px, 6px 1px, 7px 1px, 8px 1px,
    9px 1px, 10px 1px, 11px 1px, 12px 1px, 13px 1px, 14px 1px, 15px 1px, 1px 2px,
    2px 2px, 3px 2px, 4px 2px, 5px 2px, 6px 2px, 7px 2px, 8px 2px, 9px 2px,
    10px 2px, 11px 2px, 12px 2px, 13px 2px, 14px 2px, 15px 2px, 16px 2px,
    1px 3px, 2px 3px, 3px 3px, 4px 3px, 5px 3px #fff, 6px 3px #fff, 7px 3px #fff,
    8px 3px, 9px 3px, 10px 3px #fff, 11px 3px #fff, 12px 3px #fff, 13px 3px,
    14px 3px, 15px 3px, 16px 3px, 1px 4px, 2px 4px, 3px 4px, 4px 4px #fff,
    5px 4px #fff, 6px 4px, 7px 4px, 8px 4px, 9px 4px, 10px 4px, 11px 4px,
    12px 4px #fff, 13px 4px #fff, 14px 4px, 15px 4px, 16px 4px, 1px 5px, 2px 5px,
    3px 5px #fff, 4px 5px #fff, 5px 5px, 6px 5px #fff, 7px 5px #fff,
    8px 5px #fff, 9px 5px #fff, 10px 5px #fff, 11px 5px #fff, 12px 5px,
    13px 5px #fff, 14px 5px #fff, 15px 5px, 16px 5px, 1px 6px, 2px 6px,
    3px 6px #fff, 4px 6px #fff, 5px 6px #fff, 6px 6px #fff, 7px 6px #fff,
    8px 6px #fff, 9px 6px #fff, 10px 6px #fff, 11px 6px #fff, 12px 6px #fff,
    13px 6px #fff, 14px 6px #fff, 15px 6px, 16px 6px, 1px 7px, 2px 7px #fff,
    3px 7px #fff, 4px 7px #fff, 5px 7px, 6px 7px, 7px 7px #fff, 8px 7px #fff,
    9px 7px #fff, 10px 7px #fff, 11px 7px, 12px 7px, 13px 7px #fff,
    14px 7px #fff, 15px 7px #fff, 16px 7px, 1px 8px, 2px 8px #fff, 3px 8px #fff,
    4px 8px, 5px 8px, 6px 8px, 7px 8px, 8px 8px #fff, 9px 8px #fff, 10px 8px,
    11px 8px, 12px 8px, 13px 8px, 14px 8px #fff, 15px 8px #fff, 16px 8px,
    1px 9px, 2px 9px #fff, 3px 9px #fff, 4px 9px, 5px 9px, 6px 9px, 7px 9px,
    8px 9px #fff, 9px 9px #fff, 10px 9px, 11px 9px, 12px 9px, 13px 9px,
    14px 9px #fff, 15px 9px #fff, 16px 9px, 1px 10px, 2px 10px #fff,
    3px 10px #fff, 4px 10px #fff, 5px 10px, 6px 10px, 7px 10px #fff,
    8px 10px #fff, 9px 10px #fff, 10px 10px #fff, 11px 10px, 12px 10px,
    13px 10px #fff, 14px 10px #fff, 15px 10px #fff, 16px 10px, 1px 11px,
    2px 11px #fff, 3px 11px #fff, 4px 11px #fff, 5px 11px #fff, 6px 11px #fff,
    7px 11px #fff, 8px 11px #fff, 9px 11px #fff, 10px 11px #fff, 11px 11px #fff,
    12px 11px #fff, 13px 11px #fff, 14px 11px #fff, 15px 11px #fff, 16px 11px,
    1px 12px, 2px 12px #fff, 3px 12px #fff, 4px 12px #fff, 5px 12px #fff,
    6px 12px, 7px 12px #fff, 8px 12px #fff, 9px 12px #fff, 10px 12px #fff,
    11px 12px, 12px 12px #fff, 13px 12px #fff, 14px 12px #fff, 15px 12px #fff,
    16px 12px, 1px 13px, 2px 13px, 3px 13px #fff, 4px 13px #fff, 5px 13px #fff,
    6px 13px #fff, 7px 13px, 8px 13px, 9px 13px, 10px 13px, 11px 13px #fff,
    12px 13px #fff, 13px 13px #fff, 14px 13px #fff, 15px 13px, 16px 13px,
    1px 14px, 2px 14px, 3px 14px, 4px 14px #fff, 5px 14px #fff, 6px 14px,
    7px 14px, 8px 14px, 9px 14px, 10px 14px, 11px 14px, 12px 14px #fff,
    13px 14px #fff, 14px 14px, 15px 14px, 16px 14px, 1px 15px, 2px 15px,
    3px 15px, 4px 15px, 5px 15px, 6px 15px, 7px 15px, 8px 15px, 9px 15px,
    10px 15px, 11px 15px, 12px 15px, 13px 15px, 14px 15px, 15px 15px, 16px 15px,
    2px 16px, 3px 16px, 4px 16px, 5px 16px, 6px 16px, 7px 16px, 8px 16px,
    9px 16px, 10px 16px, 11px 16px, 12px 16px, 13px 16px, 14px 16px, 15px 16px;
}

@supports (-moz-appearance: meterbar) {
  .nes-icon.discord::before {
    box-shadow: 2px 1px 0 0.02em, 3px 1px 0 0.02em, 4px 1px 0 0.02em,
      5px 1px 0 0.02em, 6px 1px 0 0.02em, 7px 1px 0 0.02em, 8px 1px 0 0.02em,
      9px 1px 0 0.02em, 10px 1px 0 0.02em, 11px 1px 0 0.02em, 12px 1px 0 0.02em,
      13px 1px 0 0.02em, 14px 1px 0 0.02em, 15px 1px 0 0.02em, 1px 2px 0 0.02em,
      2px 2px 0 0.02em, 3px 2px 0 0.02em, 4px 2px 0 0.02em, 5px 2px 0 0.02em,
      6px 2px 0 0.02em, 7px 2px 0 0.02em, 8px 2px 0 0.02em, 9px 2px 0 0.02em,
      10px 2px 0 0.02em, 11px 2px 0 0.02em, 12px 2px 0 0.02em, 13px 2px 0 0.02em,
      14px 2px 0 0.02em, 15px 2px 0 0.02em, 16px 2px 0 0.02em, 1px 3px 0 0.02em,
      2px 3px 0 0.02em, 3px 3px 0 0.02em, 4px 3px 0 0.02em,
      5px 3px 0 0.02em #fff, 6px 3px 0 0.02em #fff, 7px 3px 0 0.02em #fff,
      8px 3px 0 0.02em, 9px 3px 0 0.02em, 10px 3px 0 0.02em #fff,
      11px 3px 0 0.02em #fff, 12px 3px 0 0.02em #fff, 13px 3px 0 0.02em,
      14px 3px 0 0.02em, 15px 3px 0 0.02em, 16px 3px 0 0.02em, 1px 4px 0 0.02em,
      2px 4px 0 0.02em, 3px 4px 0 0.02em, 4px 4px 0 0.02em #fff,
      5px 4px 0 0.02em #fff, 6px 4px 0 0.02em, 7px 4px 0 0.02em,
      8px 4px 0 0.02em, 9px 4px 0 0.02em, 10px 4px 0 0.02em, 11px 4px 0 0.02em,
      12px 4px 0 0.02em #fff, 13px 4px 0 0.02em #fff, 14px 4px 0 0.02em,
      15px 4px 0 0.02em, 16px 4px 0 0.02em, 1px 5px 0 0.02em, 2px 5px 0 0.02em,
      3px 5px 0 0.02em #fff, 4px 5px 0 0.02em #fff, 5px 5px 0 0.02em,
      6px 5px 0 0.02em #fff, 7px 5px 0 0.02em #fff, 8px 5px 0 0.02em #fff,
      9px 5px 0 0.02em #fff, 10px 5px 0 0.02em #fff, 11px 5px 0 0.02em #fff,
      12px 5px 0 0.02em, 13px 5px 0 0.02em #fff, 14px 5px 0 0.02em #fff,
      15px 5px 0 0.02em, 16px 5px 0 0.02em, 1px 6px 0 0.02em, 2px 6px 0 0.02em,
      3px 6px 0 0.02em #fff, 4px 6px 0 0.02em #fff, 5px 6px 0 0.02em #fff,
      6px 6px 0 0.02em #fff, 7px 6px 0 0.02em #fff, 8px 6px 0 0.02em #fff,
      9px 6px 0 0.02em #fff, 10px 6px 0 0.02em #fff, 11px 6px 0 0.02em #fff,
      12px 6px 0 0.02em #fff, 13px 6px 0 0.02em #fff, 14px 6px 0 0.02em #fff,
      15px 6px 0 0.02em, 16px 6px 0 0.02em, 1px 7px 0 0.02em,
      2px 7px 0 0.02em #fff, 3px 7px 0 0.02em #fff, 4px 7px 0 0.02em #fff,
      5px 7px 0 0.02em, 6px 7px 0 0.02em, 7px 7px 0 0.02em #fff,
      8px 7px 0 0.02em #fff, 9px 7px 0 0.02em #fff, 10px 7px 0 0.02em #fff,
      11px 7px 0 0.02em, 12px 7px 0 0.02em, 13px 7px 0 0.02em #fff,
      14px 7px 0 0.02em #fff, 15px 7px 0 0.02em #fff, 16px 7px 0 0.02em,
      1px 8px 0 0.02em, 2px 8px 0 0.02em #fff, 3px 8px 0 0.02em #fff,
      4px 8px 0 0.02em, 5px 8px 0 0.02em, 6px 8px 0 0.02em, 7px 8px 0 0.02em,
      8px 8px 0 0.02em #fff, 9px 8px 0 0.02em #fff, 10px 8px 0 0.02em,
      11px 8px 0 0.02em, 12px 8px 0 0.02em, 13px 8px 0 0.02em,
      14px 8px 0 0.02em #fff, 15px 8px 0 0.02em #fff, 16px 8px 0 0.02em,
      1px 9px 0 0.02em, 2px 9px 0 0.02em #fff, 3px 9px 0 0.02em #fff,
      4px 9px 0 0.02em, 5px 9px 0 0.02em, 6px 9px 0 0.02em, 7px 9px 0 0.02em,
      8px 9px 0 0.02em #fff, 9px 9px 0 0.02em #fff, 10px 9px 0 0.02em,
      11px 9px 0 0.02em, 12px 9px 0 0.02em, 13px 9px 0 0.02em,
      14px 9px 0 0.02em #fff, 15px 9px 0 0.02em #fff, 16px 9px 0 0.02em,
      1px 10px 0 0.02em, 2px 10px 0 0.02em #fff, 3px 10px 0 0.02em #fff,
      4px 10px 0 0.02em #fff, 5px 10px 0 0.02em, 6px 10px 0 0.02em,
      7px 10px 0 0.02em #fff, 8px 10px 0 0.02em #fff, 9px 10px 0 0.02em #fff,
      10px 10px 0 0.02em #fff, 11px 10px 0 0.02em, 12px 10px 0 0.02em,
      13px 10px 0 0.02em #fff, 14px 10px 0 0.02em #fff, 15px 10px 0 0.02em #fff,
      16px 10px 0 0.02em, 1px 11px 0 0.02em, 2px 11px 0 0.02em #fff,
      3px 11px 0 0.02em #fff, 4px 11px 0 0.02em #fff, 5px 11px 0 0.02em #fff,
      6px 11px 0 0.02em #fff, 7px 11px 0 0.02em #fff, 8px 11px 0 0.02em #fff,
      9px 11px 0 0.02em #fff, 10px 11px 0 0.02em #fff, 11px 11px 0 0.02em #fff,
      12px 11px 0 0.02em #fff, 13px 11px 0 0.02em #fff, 14px 11px 0 0.02em #fff,
      15px 11px 0 0.02em #fff, 16px 11px 0 0.02em, 1px 12px 0 0.02em,
      2px 12px 0 0.02em #fff, 3px 12px 0 0.02em #fff, 4px 12px 0 0.02em #fff,
      5px 12px 0 0.02em #fff, 6px 12px 0 0.02em, 7px 12px 0 0.02em #fff,
      8px 12px 0 0.02em #fff, 9px 12px 0 0.02em #fff, 10px 12px 0 0.02em #fff,
      11px 12px 0 0.02em, 12px 12px 0 0.02em #fff, 13px 12px 0 0.02em #fff,
      14px 12px 0 0.02em #fff, 15px 12px 0 0.02em #fff, 16px 12px 0 0.02em,
      1px 13px 0 0.02em, 2px 13px 0 0.02em, 3px 13px 0 0.02em #fff,
      4px 13px 0 0.02em #fff, 5px 13px 0 0.02em #fff, 6px 13px 0 0.02em #fff,
      7px 13px 0 0.02em, 8px 13px 0 0.02em, 9px 13px 0 0.02em,
      10px 13px 0 0.02em, 11px 13px 0 0.02em #fff, 12px 13px 0 0.02em #fff,
      13px 13px 0 0.02em #fff, 14px 13px 0 0.02em #fff, 15px 13px 0 0.02em,
      16px 13px 0 0.02em, 1px 14px 0 0.02em, 2px 14px 0 0.02em,
      3px 14px 0 0.02em, 4px 14px 0 0.02em #fff, 5px 14px 0 0.02em #fff,
      6px 14px 0 0.02em, 7px 14px 0 0.02em, 8px 14px 0 0.02em, 9px 14px 0 0.02em,
      10px 14px 0 0.02em, 11px 14px 0 0.02em, 12px 14px 0 0.02em #fff,
      13px 14px 0 0.02em #fff, 14px 14px 0 0.02em, 15px 14px 0 0.02em,
      16px 14px 0 0.02em, 1px 15px 0 0.02em, 2px 15px 0 0.02em,
      3px 15px 0 0.02em, 4px 15px 0 0.02em, 5px 15px 0 0.02em, 6px 15px 0 0.02em,
      7px 15px 0 0.02em, 8px 15px 0 0.02em, 9px 15px 0 0.02em,
      10px 15px 0 0.02em, 11px 15px 0 0.02em, 12px 15px 0 0.02em,
      13px 15px 0 0.02em, 14px 15px 0 0.02em, 15px 15px 0 0.02em,
      16px 15px 0 0.02em, 2px 16px 0 0.02em, 3px 16px 0 0.02em,
      4px 16px 0 0.02em, 5px 16px 0 0.02em, 6px 16px 0 0.02em, 7px 16px 0 0.02em,
      8px 16px 0 0.02em, 9px 16px 0 0.02em, 10px 16px 0 0.02em,
      11px 16px 0 0.02em, 12px 16px 0 0.02em, 13px 16px 0 0.02em,
      14px 16px 0 0.02em, 15px 16px 0 0.02em;
  }
}
.nes-icon.opensea::before {
  width: 1px;
  height: 1px;
  color: #2081e2;
  box-shadow: 2px 1px, 3px 1px, 4px 1px, 5px 1px, 6px 1px, 7px 1px, 8px 1px,
    9px 1px, 10px 1px, 11px 1px, 12px 1px, 13px 1px, 14px 1px, 15px 1px, 1px 2px,
    2px 2px, 3px 2px, 4px 2px, 5px 2px, 6px 2px, 7px 2px, 8px 2px, 9px 2px,
    10px 2px, 11px 2px, 12px 2px, 13px 2px, 14px 2px, 15px 2px, 16px 2px,
    1px 3px, 2px 3px, 3px 3px, 4px 3px, 5px 3px, 6px 3px, 7px 3px, 8px 3px,
    9px 3px #fff, 10px 3px, 11px 3px, 12px 3px, 13px 3px, 14px 3px, 15px 3px,
    16px 3px, 1px 4px, 2px 4px, 3px 4px, 4px 4px, 5px 4px, 6px 4px, 7px 4px,
    8px 4px, 9px 4px #fff, 10px 4px #fff, 11px 4px, 12px 4px, 13px 4px, 14px 4px,
    15px 4px, 16px 4px, 1px 5px, 2px 5px, 3px 5px, 4px 5px, 5px 5px, 6px 5px,
    7px 5px #fff, 8px 5px #fff, 9px 5px #fff, 10px 5px #fff, 11px 5px #fff,
    12px 5px, 13px 5px, 14px 5px, 15px 5px, 16px 5px, 1px 6px, 2px 6px, 3px 6px,
    4px 6px, 5px 6px, 6px 6px, 7px 6px #fff, 8px 6px #fff, 9px 6px #fff,
    10px 6px #fff, 11px 6px #fff, 12px 6px #fff, 13px 6px, 14px 6px, 15px 6px,
    16px 6px, 1px 7px, 2px 7px, 3px 7px, 4px 7px, 5px 7px #fff, 6px 7px, 7px 7px,
    8px 7px, 9px 7px #fff, 10px 7px #fff, 11px 7px #fff, 12px 7px #fff, 13px 7px,
    14px 7px, 15px 7px, 16px 7px, 1px 8px, 2px 8px, 3px 8px, 4px 8px #fff,
    5px 8px #fff, 6px 8px #fff, 7px 8px, 8px 8px, 9px 8px #fff, 10px 8px #fff,
    11px 8px #fff, 12px 8px #fff, 13px 8px #fff, 14px 8px, 15px 8px, 16px 8px,
    1px 9px, 2px 9px, 3px 9px #fff, 4px 9px #fff, 5px 9px #fff, 6px 9px #fff,
    7px 9px, 8px 9px, 9px 9px #fff, 10px 9px #fff, 11px 9px #fff, 12px 9px #fff,
    13px 9px #fff, 14px 9px, 15px 9px, 16px 9px, 1px 10px, 2px 10px #fff,
    3px 10px #fff, 4px 10px #fff, 5px 10px #fff, 6px 10px, 7px 10px #fff,
    8px 10px #fff, 9px 10px #fff, 10px 10px #fff, 11px 10px #fff, 12px 10px #fff,
    13px 10px, 14px 10px, 15px 10px, 16px 10px, 1px 11px, 2px 11px, 3px 11px,
    4px 11px, 5px 11px, 6px 11px, 7px 11px #fff, 8px 11px #fff, 9px 11px #fff,
    10px 11px #fff, 11px 11px, 12px 11px, 13px 11px, 14px 11px, 15px 11px,
    16px 11px, 1px 12px, 2px 12px #fff, 3px 12px #fff, 4px 12px #fff,
    5px 12px #fff, 6px 12px, 7px 12px, 8px 12px, 9px 12px #fff, 10px 12px #fff,
    11px 12px, 12px 12px #fff, 13px 12px #fff, 14px 12px #fff, 15px 12px #fff,
    16px 12px, 1px 13px, 2px 13px, 3px 13px #fff, 4px 13px #fff, 5px 13px #fff,
    6px 13px #fff, 7px 13px #fff, 8px 13px #fff, 9px 13px #fff, 10px 13px #fff,
    11px 13px #fff, 12px 13px #fff, 13px 13px #fff, 14px 13px #fff, 15px 13px,
    16px 13px, 1px 14px, 2px 14px, 3px 14px, 4px 14px #fff, 5px 14px #fff,
    6px 14px #fff, 7px 14px #fff, 8px 14px #fff, 9px 14px #fff, 10px 14px #fff,
    11px 14px #fff, 12px 14px #fff, 13px 14px #fff, 14px 14px, 15px 14px,
    16px 14px, 1px 15px, 2px 15px, 3px 15px, 4px 15px, 5px 15px, 6px 15px,
    7px 15px, 8px 15px, 9px 15px, 10px 15px, 11px 15px, 12px 15px, 13px 15px,
    14px 15px, 15px 15px, 16px 15px, 2px 16px, 3px 16px, 4px 16px, 5px 16px,
    6px 16px, 7px 16px, 8px 16px, 9px 16px, 10px 16px, 11px 16px, 12px 16px,
    13px 16px, 14px 16px, 15px 16px;
}

@supports (-moz-appearance: meterbar) {
  .nes-icon.opensea::before {
    box-shadow: 2px 1px 0 0.02em, 3px 1px 0 0.02em, 4px 1px 0 0.02em,
      5px 1px 0 0.02em, 6px 1px 0 0.02em, 7px 1px 0 0.02em, 8px 1px 0 0.02em,
      9px 1px 0 0.02em, 10px 1px 0 0.02em, 11px 1px 0 0.02em, 12px 1px 0 0.02em,
      13px 1px 0 0.02em, 14px 1px 0 0.02em, 15px 1px 0 0.02em, 1px 2px 0 0.02em,
      2px 2px 0 0.02em, 3px 2px 0 0.02em, 4px 2px 0 0.02em, 5px 2px 0 0.02em,
      6px 2px 0 0.02em, 7px 2px 0 0.02em, 8px 2px 0 0.02em, 9px 2px 0 0.02em,
      10px 2px 0 0.02em, 11px 2px 0 0.02em, 12px 2px 0 0.02em, 13px 2px 0 0.02em,
      14px 2px 0 0.02em, 15px 2px 0 0.02em, 16px 2px 0 0.02em, 1px 3px 0 0.02em,
      2px 3px 0 0.02em, 3px 3px 0 0.02em, 4px 3px 0 0.02em, 5px 3px 0 0.02em,
      6px 3px 0 0.02em, 7px 3px 0 0.02em, 8px 3px 0 0.02em,
      9px 3px 0 0.02em #fff, 10px 3px 0 0.02em, 11px 3px 0 0.02em,
      12px 3px 0 0.02em, 13px 3px 0 0.02em, 14px 3px 0 0.02em, 15px 3px 0 0.02em,
      16px 3px 0 0.02em, 1px 4px 0 0.02em, 2px 4px 0 0.02em, 3px 4px 0 0.02em,
      4px 4px 0 0.02em, 5px 4px 0 0.02em, 6px 4px 0 0.02em, 7px 4px 0 0.02em,
      8px 4px 0 0.02em, 9px 4px 0 0.02em #fff, 10px 4px 0 0.02em #fff,
      11px 4px 0 0.02em, 12px 4px 0 0.02em, 13px 4px 0 0.02em, 14px 4px 0 0.02em,
      15px 4px 0 0.02em, 16px 4px 0 0.02em, 1px 5px 0 0.02em, 2px 5px 0 0.02em,
      3px 5px 0 0.02em, 4px 5px 0 0.02em, 5px 5px 0 0.02em, 6px 5px 0 0.02em,
      7px 5px 0 0.02em #fff, 8px 5px 0 0.02em #fff, 9px 5px 0 0.02em #fff,
      10px 5px 0 0.02em #fff, 11px 5px 0 0.02em #fff, 12px 5px 0 0.02em,
      13px 5px 0 0.02em, 14px 5px 0 0.02em, 15px 5px 0 0.02em, 16px 5px 0 0.02em,
      1px 6px 0 0.02em, 2px 6px 0 0.02em, 3px 6px 0 0.02em, 4px 6px 0 0.02em,
      5px 6px 0 0.02em, 6px 6px 0 0.02em, 7px 6px 0 0.02em #fff,
      8px 6px 0 0.02em #fff, 9px 6px 0 0.02em #fff, 10px 6px 0 0.02em #fff,
      11px 6px 0 0.02em #fff, 12px 6px 0 0.02em #fff, 13px 6px 0 0.02em,
      14px 6px 0 0.02em, 15px 6px 0 0.02em, 16px 6px 0 0.02em, 1px 7px 0 0.02em,
      2px 7px 0 0.02em, 3px 7px 0 0.02em, 4px 7px 0 0.02em,
      5px 7px 0 0.02em #fff, 6px 7px 0 0.02em, 7px 7px 0 0.02em,
      8px 7px 0 0.02em, 9px 7px 0 0.02em #fff, 10px 7px 0 0.02em #fff,
      11px 7px 0 0.02em #fff, 12px 7px 0 0.02em #fff, 13px 7px 0 0.02em,
      14px 7px 0 0.02em, 15px 7px 0 0.02em, 16px 7px 0 0.02em, 1px 8px 0 0.02em,
      2px 8px 0 0.02em, 3px 8px 0 0.02em, 4px 8px 0 0.02em #fff,
      5px 8px 0 0.02em #fff, 6px 8px 0 0.02em #fff, 7px 8px 0 0.02em,
      8px 8px 0 0.02em, 9px 8px 0 0.02em #fff, 10px 8px 0 0.02em #fff,
      11px 8px 0 0.02em #fff, 12px 8px 0 0.02em #fff, 13px 8px 0 0.02em #fff,
      14px 8px 0 0.02em, 15px 8px 0 0.02em, 16px 8px 0 0.02em, 1px 9px 0 0.02em,
      2px 9px 0 0.02em, 3px 9px 0 0.02em #fff, 4px 9px 0 0.02em #fff,
      5px 9px 0 0.02em #fff, 6px 9px 0 0.02em #fff, 7px 9px 0 0.02em,
      8px 9px 0 0.02em, 9px 9px 0 0.02em #fff, 10px 9px 0 0.02em #fff,
      11px 9px 0 0.02em #fff, 12px 9px 0 0.02em #fff, 13px 9px 0 0.02em #fff,
      14px 9px 0 0.02em, 15px 9px 0 0.02em, 16px 9px 0 0.02em, 1px 10px 0 0.02em,
      2px 10px 0 0.02em #fff, 3px 10px 0 0.02em #fff, 4px 10px 0 0.02em #fff,
      5px 10px 0 0.02em #fff, 6px 10px 0 0.02em, 7px 10px 0 0.02em #fff,
      8px 10px 0 0.02em #fff, 9px 10px 0 0.02em #fff, 10px 10px 0 0.02em #fff,
      11px 10px 0 0.02em #fff, 12px 10px 0 0.02em #fff, 13px 10px 0 0.02em,
      14px 10px 0 0.02em, 15px 10px 0 0.02em, 16px 10px 0 0.02em,
      1px 11px 0 0.02em, 2px 11px 0 0.02em, 3px 11px 0 0.02em, 4px 11px 0 0.02em,
      5px 11px 0 0.02em, 6px 11px 0 0.02em, 7px 11px 0 0.02em #fff,
      8px 11px 0 0.02em #fff, 9px 11px 0 0.02em #fff, 10px 11px 0 0.02em #fff,
      11px 11px 0 0.02em, 12px 11px 0 0.02em, 13px 11px 0 0.02em,
      14px 11px 0 0.02em, 15px 11px 0 0.02em, 16px 11px 0 0.02em,
      1px 12px 0 0.02em, 2px 12px 0 0.02em #fff, 3px 12px 0 0.02em #fff,
      4px 12px 0 0.02em #fff, 5px 12px 0 0.02em #fff, 6px 12px 0 0.02em,
      7px 12px 0 0.02em, 8px 12px 0 0.02em, 9px 12px 0 0.02em #fff,
      10px 12px 0 0.02em #fff, 11px 12px 0 0.02em, 12px 12px 0 0.02em #fff,
      13px 12px 0 0.02em #fff, 14px 12px 0 0.02em #fff, 15px 12px 0 0.02em #fff,
      16px 12px 0 0.02em, 1px 13px 0 0.02em, 2px 13px 0 0.02em,
      3px 13px 0 0.02em #fff, 4px 13px 0 0.02em #fff, 5px 13px 0 0.02em #fff,
      6px 13px 0 0.02em #fff, 7px 13px 0 0.02em #fff, 8px 13px 0 0.02em #fff,
      9px 13px 0 0.02em #fff, 10px 13px 0 0.02em #fff, 11px 13px 0 0.02em #fff,
      12px 13px 0 0.02em #fff, 13px 13px 0 0.02em #fff, 14px 13px 0 0.02em #fff,
      15px 13px 0 0.02em, 16px 13px 0 0.02em, 1px 14px 0 0.02em,
      2px 14px 0 0.02em, 3px 14px 0 0.02em, 4px 14px 0 0.02em #fff,
      5px 14px 0 0.02em #fff, 6px 14px 0 0.02em #fff, 7px 14px 0 0.02em #fff,
      8px 14px 0 0.02em #fff, 9px 14px 0 0.02em #fff, 10px 14px 0 0.02em #fff,
      11px 14px 0 0.02em #fff, 12px 14px 0 0.02em #fff, 13px 14px 0 0.02em #fff,
      14px 14px 0 0.02em, 15px 14px 0 0.02em, 16px 14px 0 0.02em,
      1px 15px 0 0.02em, 2px 15px 0 0.02em, 3px 15px 0 0.02em, 4px 15px 0 0.02em,
      5px 15px 0 0.02em, 6px 15px 0 0.02em, 7px 15px 0 0.02em, 8px 15px 0 0.02em,
      9px 15px 0 0.02em, 10px 15px 0 0.02em, 11px 15px 0 0.02em,
      12px 15px 0 0.02em, 13px 15px 0 0.02em, 14px 15px 0 0.02em,
      15px 15px 0 0.02em, 16px 15px 0 0.02em, 2px 16px 0 0.02em,
      3px 16px 0 0.02em, 4px 16px 0 0.02em, 5px 16px 0 0.02em, 6px 16px 0 0.02em,
      7px 16px 0 0.02em, 8px 16px 0 0.02em, 9px 16px 0 0.02em,
      10px 16px 0 0.02em, 11px 16px 0 0.02em, 12px 16px 0 0.02em,
      13px 16px 0 0.02em, 14px 16px 0 0.02em, 15px 16px 0 0.02em;
  }
}
